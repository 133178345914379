import React from 'react';
import './styles.css';
import x from "../../Images/Icons/x.svg";
import { useHasProfile } from "../../hooks/useHasProfile";

const SelectMoverProgram = ({ innovationLawYear, moverProgramYears, onAddDate, onRemoveDate }) => {
  const [selectedYear, setSelectedYear] = React.useState("");
  const hasAccess = useHasProfile("administrator");
  const moverProgramYearsAsNumbers = moverProgramYears.map(Number);
  const availableYears = Array.from(innovationLawYear).sort();
  
  const handleAddDate = (event) => {
    const selectedYear = Number(event.target.value);
    if (selectedYear && !moverProgramYearsAsNumbers.includes(selectedYear)) {
      onAddDate(selectedYear);
      setSelectedYear("");
    }
  };

  const YearBadge = ({ year }) => (
    <div key={year} className="year-badge">
      <span>{year}</span>
      {hasAccess && (
        <button
          onClick={() => onRemoveDate(Number(year))}
          className="remove-button"
          aria-label={`Remover ano ${year}`}
        >
          <img src={x} alt="X icon" />
        </button>
      )}
    </div>
  );

  return (
      <div className="container-mover-program">
        <label
          htmlFor="year-select-mover-program"
          className="mover-program-label-text"
          id="year-select-mover-program-label"
        >
          Apropriado no programa Mover em:
        </label>

        <div className="years-container">
          <div className="badges-container" aria-live="polite" role="status">
            {moverProgramYears.length > 0 ? (
              moverProgramYears.map((year) => (
                <YearBadge
                  key={year}
                  year={year}
                  aria-label={`Ano selecionado: ${year}`}
                />
              ))
            ) : (
              <div className="no-date">Nenhuma data adicionada.</div>
            )}
          </div>

          {hasAccess && (
          <select
            id="year-select"
            value={selectedYear}
            onChange={handleAddDate}
            className="select-year"
            aria-labelledby="year-select-label"
            aria-required="true"
            aria-invalid={selectedYear === "" ? "true" : "false"}
          >
            <option value="">Selecione o ano</option>
            {availableYears
              .filter((year) => !moverProgramYearsAsNumbers.includes(year))
              .map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        )}
        </div>
      </div>     
  );
};

export default SelectMoverProgram;